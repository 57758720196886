/** @jsx jsx */
import React, { Fragment } from "react";
import { Container, Row, Col } from "react-bootstrap"
import "react-modal-video/css/modal-video.min.css"
import { jsx } from '@emotion/react'
import IconExelencia from '../assets/images/lp/elos/icons/Excelencia.svg'
import IconReponsabilidade from '../assets/images/lp/elos/icons/Responsabilidade-social.svg'
import IconResilencia from '../assets/images/lp/elos/icons/Resilencia.svg'
import IconParceria from '../assets/images/lp/elos/icons/Parceria.svg'
import IconOrientacao from '../assets/images/lp/elos/icons/Orientacao.svg'
import IconIntegridade from '../assets/images/lp/elos/icons/Integridade.svg'
import IconEquilibrio from '../assets/images/lp/elos/icons/Equilibrio.svg'
import IconEmpatia from '../assets/images/lp/elos/icons/Empatia.svg'
import IconDisciplina from '../assets/images/lp/elos/icons/Disciplina.svg'
import IconCriatividade from '../assets/images/lp/elos/icons/Criatividade.svg'

import { 
  boxContent,
  elos,
}  from '../assets/styles/ElosContent.styles'

const OrigemPage = () => {
  return (
    <Fragment>
      <section css={elos}>
        <Container>
          <Row>
            <Col lg={1} sm={1} />
            <Col lg={5} sm={12} data-aos-delay={"300"} data-aos="fade-right">
              <div css={boxContent}>
                <img src={IconExelencia} alt='/'/>
                <h2>Excelência</h2>
                <p>Excelência como princípio: façamos de cada ato, um incentivo ao aprendizado como imersão. Educação como uma jornada estimulante e desafiadora em busca de conhecimentos de alto nível.</p>
              </div>
            </Col>
            <Col lg={5} sm={12} data-aos-delay={"300"} data-aos="fade-left">
              <div css={boxContent}>
                <img src={IconIntegridade} alt='/'/>
                <h2>Integridade</h2>
                <p>Honestidade, ética e respeito como base para a construção de um ambiente seguro e colaborativo. O essencial é diferencial e todos são bem-vindos a participar dessa experiência.</p>
              </div>
            </Col>
            <Col lg={1} sm={1} />
          </Row>
          <Row>
            <Col lg={1} sm={1} />
            <Col lg={5} sm={12} data-aos-delay={"400"} data-aos="fade-right">
              <div css={boxContent}>
                <img src={IconDisciplina} alt='/'/>
                <h2>Disciplina</h2>
                <p>Disciplina produz autonomia. Só com dedicação, esforço e disciplina é possível manter processos bem estruturados, pessoas engajadas e metodologias inovadoras.</p>
              </div>
            </Col>
            <Col lg={5} sm={12} data-aos-delay={"400"} data-aos="fade-left">
              <div css={boxContent}>
                <img src={IconCriatividade} alt='/'/>
                <h2>Criatividade</h2>
                <p>Resolver problemas continuará sendo a competência mais valorizada em um mundo cada vez mais diverso e conflituoso. Estimular o pensamento criativo é um compromisso nosso com as próximas gerações.</p>
              </div>
            </Col>
            <Col lg={1} sm={1} />
          </Row>
          <Row>
            <Col lg={1} sm={1} />
            <Col lg={5} sm={12} data-aos-delay={"500"} data-aos="fade-right">
              <div css={boxContent}>
                <img src={IconParceria} alt='/'/>
                <h2>Parceria</h2>
                <p>A escola é uma rede. É onde colaboração, trabalho em equipe e participação ativa dos pais e familiares se articulam para transformar a sociedade. A escola deve atuar em parceria com as comunidades local e global para se expandir além dos desafios da educação formal.</p>
              </div>
            </Col>
            <Col lg={5} sm={12} data-aos-delay={"500"} data-aos="fade-left">
              <div css={boxContent}>
                <img src={IconEmpatia} alt='/'/>
                <h2>Empatia</h2>
                <p>Para ensinar e aprender é preciso compreender. Empatia é um exercício de reconhecimento do outro, de valorização da diversidade e de usar as diferentes origens e visões de mundo para fortalecer nossa cultura.</p>
              </div>
            </Col>
            <Col lg={1} sm={1} />
          </Row>
          <Row>
            <Col lg={1} sm={1} />
            <Col lg={5} sm={12} data-aos-delay={"600"} data-aos="fade-right">
              <div css={boxContent}>
                <img src={IconResilencia} alt='/'/>
                <h2>Resiliência</h2>
                <p>A escola é uma rede. É onde colaboração, trabalho em equipe e participação ativa dos pais e familiares se articulam para transformar a sociedade. A escola deve atuar em parceria com as comunidades local e global para se expandir além dos desafios da educação formal.</p>
              </div>
            </Col>
            <Col lg={5} sm={12} data-aos-delay={"600"} data-aos="fade-left">
              <div css={boxContent}>
                <img src={IconReponsabilidade} alt='/'/>
                <h2>Responsabilidade social</h2>
                <p>Devemos estimular nossos alunos a despertarem consciência social e responsabilidade cívica desde cedo. É preciso dar todo o suporte possível a ações e projetos comunitários para que formemos cidadãos prontos para serem agentes de mudança em suas comunidades.</p>
              </div>
            </Col>
            <Col lg={1} sm={1} />
          </Row>
          <Row>
            <Col lg={1} sm={1} />
            <Col lg={5} sm={12} data-aos-delay={"700"} data-aos="fade-right">
              <div css={boxContent}>
                <img src={IconEquilibrio} alt='/'/>
                <h2>Equilíbrio</h2>
                <p>Educação é a vida. É importante reconhecer que quando conhecimento e diversão dançam juntos no mesmo compasso de corações e mentes saudáveis, a vida segue seu curso de maneira natural. Reconhecemos a importância de harmonizar estudos e vida pessoal, incentivando hábitos saudáveis e momentos de lazer.</p>
              </div>
            </Col>
            <Col lg={5} sm={12} data-aos-delay={"700"} data-aos="fade-left">
              <div css={boxContent}>
                <img src={IconOrientacao} alt='/'/>
                <h2>Orientação para resultados</h2>
                <p>A escola deve orientar e direcionar pais, alunos e a comunidade por trilhas de aprendizagem. A busca por resultados deve seguir metas claras, acompanhamento constante e bom nível de personalização para que os alunos estejam preparados para progredir emocional e academicamente</p>
              </div>
            </Col>
            <Col lg={1} sm={1} />
          </Row>
        </Container>
      </section>
    </Fragment>
  );
};

export default OrigemPage;
